<template>
  <div class="container text-start px-5" v-if="inREE">
    <h4 v-if="createLead">Create Referral</h4>
    <h4 v-else>Create Referral Rock Member</h4>
  </div>
  <div class="container text-start px-5">
    <div class="row mb-3">
      <div class="col-3">
        <label for="inputFirstName" class="col-form-label">
          First Name<span class="text-danger">*</span>
        </label>
      </div>
      <div class="col">
        <input type="text" id="inputFirstName" 
          placeholder="example: John"
          class="form-control" aria-describedby="firstNameHelpInline" 
          v-model="formData.firstName"
          :disabled="successMessage.show">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3">
        <label for="inputLastName" class="col-form-label">
          Last Name<span class="text-danger">*</span>
        </label>
      </div>
      <div class="col">
        <input type="text" id="inputLastName" 
          placeholder="example: Smith"
          class="form-control" aria-describedby="lastNameHelpInline"
          v-model="formData.lastName"
          :disabled="successMessage.show">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3">
        <label for="inputEmail" class="col-form-label">
          Email<span class="text-danger">*</span>
        </label>
      </div>
      <div class="col">
        <input type="email" id="inputEmail" 
          placeholder="example: email@domain.com"
          class="form-control" aria-describedby="emailHelpInline"
          v-model="formData.email"
          :disabled="successMessage.show">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3">
        <label for="inputPhone" class="col-form-label">
          Phone<span class="text-danger">*</span>
        </label>
      </div>
      <div class="col">
        <input type="number" id="inputPhone" 
          placeholder="example: 8085551234"
          class="form-control" aria-describedby="phoneHelpInline"
          v-model="formData.phone"
          :disabled="successMessage.show">
      </div>
    </div>
    <div class="row mb-3" v-if="createLead">
      <div class="col-3">
        <label for="inputReferralCode" class="col-form-label">Referral Code</label>
      </div>
      <div class="col">
        <input type="text" id="inputReferralCode" 
          placeholder="example: XXXXX1234"
          class="form-control" aria-describedby="referralCodeInline"
          v-model="formData.referralCode"
          :disabled="successMessage.show">
      </div>
    </div>
    <div class="row mb-3" v-if="!createLead">
      <div class="col-3">
        <label for="payItForward" class="col-form-label">
          Award Preference<span class="text-danger">*</span>
        </label>
      </div>
      <div class="col">
        <select id="payItForward" class="form-control" 
          v-model="formData.payItForward" 
          :disabled="successMessage.show">
          <option v-for="pif in payItForward" :key="pif.value" :value="pif.value">
            {{ pif.option }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mb-3" v-if="inREE">
      <div class="col-3">
        <label for="inputPayItFwd" class="col-form-label">
          Navigator<span class="text-danger">*</span>
        </label>
      </div>
      <div class="col" v-if="!loadInProgressNavigators">
        <select id="payItForward" class="form-control" 
          v-model="formData.navigator" 
          :disabled="successMessage.show">
          <option v-for="nav in navigators" :key="nav.id" :value="nav.email">
            {{ nav.full_name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row text-center" v-if="successMessage.show">
      <div class="col">
        <p v-if="successMessage.referralCode">Referral Code: {{ successMessage.referralCode }}</p>
        <p v-if="successMessage.referralLink">Referral Link: {{ successMessage.referralLink }}</p>
        <p v-if="successMessage.message">{{ successMessage.message }}</p>
      </div>
    </div>
    <div class="row text-danger text-center" v-if="errorMessage.show">
      <div class="col">
        {{ errorMessage.message }}
      </div>
    </div>
    <div class="row text-end" v-if="!inREE || !loadInProgressNavigators">
      <div class="col-3">&nbsp;</div>
      <div class="col">
        <button type="button" class="btn btn-primary" @click="createMe" :disabled="successMessage.show">
          <span class="indicator-label" v-if="!createInProgress">
            Create
          </span>
          <span class="indicator-progress" v-if="createInProgress">
            Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { firebaseFunctions } from "@/firebase";
import { httpsCallable } from "firebase/functions";
import { useRoute } from 'vue-router';

export default defineComponent({
  name: "referral-rock",
  components: {},
  setup() {
    const errorMessage = ref({
      'show': false,
      'message': null,
    });
    const successMessage = ref({
      'show': false,
      'referralCode': null,
      'referralLink': null,
      'message': null,
    });
    const createInProgress = ref(false);
    const formData = ref({
      'firstName': null,
      'lastName': null,
      'email': null,
      'phone': null,
      'payItForward': null,
      'navigator': null,
      'referralCode': null,
    });
    const payItForward = ref([
      { 'value': 100, 'option': "$100 Cash" },
      { 'value': -100, 'option': "$100 Discount" },
    ]);
    const inREE = ref(false);
    const createLead = ref(false);
    const loadInProgressNavigators = ref(true);
    const navigators = ref([]);

    const route = useRoute();
    // Names
    if (route.query.name !== undefined) {
      const name = route.query.name.split(" ");
      formData.value.firstName = name[0];
      name.shift();
      formData.value.lastName = name.join(" ");
      // Email
      formData.value.email = route.query.email;
      // Phone
      formData.value.phone = route.query.phone;
    }
    // is from REE Zoho?
    if (route.query.isREE !== undefined) {
      if (route.query.isREE === 'true') {
        inREE.value = true;
      }
    }
    // Creating a Lead?
    if (route.query.createLead !== undefined) {
      if (route.query.createLead === 'true') {
        createLead.value = true;
      }
    }
    // First Name
    if (route.query.firstName !== undefined && 
      route.query.firstName !== null && 
      route.query.firstName !== "") {
      formData.value.firstName = route.query.firstName;
    }
    // Last Name
    if (route.query.lastName !== undefined && 
      route.query.lastName !== null && 
      route.query.lastName !== "") {
      formData.value.lastName = route.query.lastName;
    }
    // Email
    if (route.query.email !== undefined && 
      route.query.email !== null && 
      route.query.email !== "") {
      formData.value.email = route.query.email;
    }
    // Phone
    if (route.query.phone !== undefined && 
      route.query.phone !== null && 
      route.query.phone !== "") {
      formData.value.phone = route.query.phone;
    }
    // Referral Code
    if (route.query.referralCode !== undefined && 
      route.query.referralCode !== null && 
      route.query.referralCode !== "") {
      formData.value.referralCode = route.query.referralCode;
    }
    
    const loadNavigators = () => {
      const rrAPI = httpsCallable(firebaseFunctions, "referralRock", { limitedUseAppCheckTokens: true });
      rrAPI({ action: "getNavigators"}).then((result) => {
        navigators.value = result.data.users;
        loadInProgressNavigators.value = false;
        // Navigator
        if (route.query.cent !== undefined && 
          route.query.cent !== null && 
          route.query.cent !== "") {
          formData.value.navigator = route.query.cent;
        }
      });
    };

    const createMe = async () => {
      let proceedCreate = false;
      errorMessage.value.show = false;
      errorMessage.value.message = null;
      // Validate
      if (formData.value.firstName !== '' && formData.value.lastName !== '' && 
          formData.value.firstName !== null &&  formData.value.lastName !== null) {
        // if inREE, then at email and phone is needed
        if (inREE.value && 
          formData.value.email !== null && formData.value.email !== '' &&
          formData.value.phone !== null && formData.value.phone !== '' && 
          formData.value.navigator !== null && 
          formData.value.navigator !== '') {
          // Not Create Lead
          if (createLead.value || (formData.value.payItForward !== '' && formData.value.payItForward !== null)) {
            proceedCreate = true;
          }
        } else if (formData.value.email !== null && formData.value.email !== '' &&
          formData.value.payItForward !== '' && formData.value.payItForward !== null) {
          proceedCreate = true;
        }
        // Only proceed when all fields required are populated
        if (proceedCreate) {
          createInProgress.value = true;
          const rrAPI = httpsCallable(firebaseFunctions, "referralRock", { limitedUseAppCheckTokens: true });
          if (createLead.value) {
            let leadFound = false;
            let referralFound = false;
            // let referralId = "";
            let referralMember = "";
            if (formData.value.email !== null && formData.value.email !== undefined && formData.value.email !== "") {
              const searchResponse = await rrAPI({ action: "searchReferral", payload: formData.value.email });
              // See if there is a referral Found
              if (searchResponse.data.referrals !== null && searchResponse.data.referrals.length > 0) {
                for (var i = 0;i < searchResponse.data.referrals.length;i++) {
                  const referral = searchResponse.data.referrals[i];
                  if (referral['email'].toLowerCase() === formData.value.email.toLowerCase()) {
                    referralFound = true;
                    // referralId = referral.id;
                    referralMember = `${referral.referringMemberName} (${referral.memberEmail})`;
                  }
                }
              }
            }
            // Referral Found
            if (referralFound) {
              errorMessage.value.show = true;
              errorMessage.value.message = `Referral ${formData.value.email} already exists in Referral Rock.`;
              errorMessage.value.message = `${errorMessage.value.message} Lead was referred by Member: ${referralMember}.`;
              errorMessage.value.message = `${errorMessage.value.message} Feel free to ask if they have another lead.`;
              createInProgress.value = false;
            } else { // Search if in CRM
              const searchLeadResponse = await rrAPI({ action: "searchLead", payload: formData.value });
              if (searchLeadResponse.leadId !== null && searchLeadResponse.leadId !== undefined && searchLeadResponse.leadId !== "") {
                leadFound = true;
                errorMessage.value.show = true;
                errorMessage.value.message = `Referral ${formData.value.email} and/or ${formData.value.phone} already exists in CRM w/ ID: ${searchLeadResponse.leadId}.`;
                errorMessage.value.message = `${errorMessage.value.message} Feel free to ask if they have another lead.`;
                createInProgress.value = false;
              }
            }
            // If it does not exist as a referral and lead in CRM, create as a referral
            if (!leadFound && !referralFound) { 
              rrAPI({ action: "addReferral", payload: formData.value }).then((result) => {
                console.log(result.data.referral);
                successMessage.value.show = true;
                if (result.data.referral !== null) {
                  successMessage.value.referralCode = null;
                  successMessage.value.referralLink = null;
                  successMessage.value.message = "Lead/Referral created successfully";
                  createInProgress.value = false;
                }
              }).catch((err) => {
                createInProgress.value = false;
                errorMessage.value.show = true;
                errorMessage.value.message = err;  
              });
            }
            createInProgress.value = false;
          } else {
            rrAPI({ action: "addMember", payload: formData.value }).then((result) => {
              console.log(result.data.member);
              successMessage.value.show = true;
              if (result.data.member !== null) {
                successMessage.value.referralCode = result.data.member.referralCode;
                successMessage.value.referralLink = result.data.member.referralUrl;
                createInProgress.value = false;
              } else {
                if (result.data.message === "Email address is already being used by existing member") {
                  rrAPI({ action: "searchMember", payload: formData.value.email }).then((response) => {
                    if (response.data.members !== null && response.data.members.length > 0) {
                      let memberFound = false;
                      let memberId = "";
                      for (var i = 0;i < response.data.members.length;i++) {
                        const member = response.data.members[i];
                        if (member['email'] === formData.value.email) {
                          successMessage.value.referralCode = member.referralCode;
                          successMessage.value.referralLink = member.referralUrl;
                          memberFound = true;
                          memberId = member.id;
                        }
                      }
                      // Member Found
                      if (memberFound) {
                        rrAPI({ action: "updateMemberInCRM", payload: { 
                          "Email": formData.value.email,
                          "ReferralCode": successMessage.value.referralCode,
                          "navigator": formData.value.navigator,
                        } }).then((updateResponse) => {
                          console.log(updateResponse);
                          // Only update if they chose discount
                          if (formData.value.payItForward < 0 && memberId != "") {
                            rrAPI({ action: "updateMember", payload: {
                              "member_id": memberId,
                              "payItForward": formData.value.payItForward,
                              "navigator": formData.value.navigator,
                            } });
                          }
                        });
                      }
                    }
                    createInProgress.value = false;
                  }).catch((err) => {
                    errorMessage.value.show = true;
                    errorMessage.value.message = err;  
                    createInProgress.value = false;
                  });
                } else {
                  errorMessage.value.show = true;
                  errorMessage.value.message = result.data.message;    
                  createInProgress.value = false;
                }
              }
            }).catch((err) => {
              createInProgress.value = false;
              errorMessage.value.show = true;
              errorMessage.value.message = err;  
            });
          }
        } else {
          errorMessage.value.show = true;
          if (inREE.value) {
            errorMessage.value.message = "First Name, Last Name, Email, Phone, 'Award Preference' and Navigator are required fields.";
            if (createLead.value) {
              errorMessage.value.message = "First Name, Last Name, Email, Phone and Navigator are required fields.";
            }
          } else {
            errorMessage.value.message = "First Name, Last Name, Email and 'Award Preference' are required fields.";    
          }
        }
      } else {
        errorMessage.value.show = true;
        errorMessage.value.message = "First Name, Last Name, Email and 'Award Preference' are required fields.";
        if (inREE.value) {
          errorMessage.value.message = "First Name, Last Name, Email/Phone, 'Award Preference' and Navigator are required fields.";
          if (createLead.value) {
            errorMessage.value.message = "First Name, Last Name, Email/Phone and Navigator are required fields.";
          }
        }
      }
    }

    // Load CENT Navigator Names
    if (inREE.value) {
      loadNavigators();
    }

    return {
      errorMessage,
      successMessage,
      createInProgress,
      formData,
      payItForward,
      loadInProgressNavigators,
      inREE,
      createLead,
      navigators,
      createMe,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>